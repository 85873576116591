// 
// widgets.scss
//

// widget-box-2
.widget-box-2 {
    .widget-detail-2 {
      .badge {
        padding: 5px 10px;
      }
    }
}


// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        border-bottom: 1px solid rgba($border-color,0.5);
        overflow: hidden;
        padding: 0.75rem 0;
        position: relative;
        &:last-child {
            border: none;
        }
        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;
            img {
                width: 40px;
            }
        }
        .inbox-item-author {
            color: $dark;
            display: block;
            margin-bottom: 3px;
        }
        .inbox-item-text {
            color: $gray-600;
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }
        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}
