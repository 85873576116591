//
// dropify.scss
//

@font-face {
    font-family: 'dropify';
    src: url("../fonts/dropify.eot");
    src: url("../fonts/dropify.eot#iefix") format("embedded-opentype"), url("../fonts/dropify.woff") format("woff"), url("../fonts/dropify.ttf") format("truetype"), url("../fonts/dropify.svg#dropify") format("svg");
    font-weight: normal;
    font-style: normal;
}

.dropify-wrapper {
    border: 2px dashed $gray-300;
    background: $card-bg;
    border-radius: 6px;
    color: $body-color;

    &:hover{
        background-image: linear-gradient(-45deg, rgba($gray-300, 0.4) 25%,transparent 25%,transparent 50%,rgba($gray-300, 0.4) 50%, rgba($gray-300, 0.4) 75%,transparent 75%,transparent);
    }

    .dropify-preview{
        background-color: $gray-300;
    }

    .dropify-message {
        p {
            font-size: 15px;
        }
    }
}